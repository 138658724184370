import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Sex Abuse By Priests",
    heroH1: "Priest Sexual Abuse",
    heroH2: "When experience, integrity, and results matter.",
    description:
      "At Bartlett & Grippe we understand the devastating long-term effects and consequences and can help you seek compensation for your physical and emotional losses. Contact us.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>We represent those sexually abused as children by priests.</h2>
            <p className="mb-8">
              We have learned much over the years from our clients about the
              devastating long-term effects and far-reaching consequences of
              childhood sexual abuse by priests. We have seen how lives have
              been drastically changed due to the use of drugs and alcohol in an
              attempt to deal with abuse. We help you understand that this was
              not your fault and that you are not alone. Clients have expressed
              great relief by finally talking about their experiences, taking
              action, and having justice served. Our firm’s motto is “Giving
              Victims a Voice”. We often hear, why isn’t anyone doing anything?
              Bartlett & Grippe is doing something and you can too. We have
              successfully represented many children and adults sexually abused
              as children. We can help you, and you can help us make a
              difference. Our attorneys are parents and believe that a child is
              the most precious gift possible. Stealing your childhood by sex
              abuse can never be fully compensated. We will do our very best to
              help you get repaid for your losses, both physical and emotional.
            </p>
            <h2>1) Will my secret be made public?</h2>
            <p className="mb-8">
              One thing we repeatedly hear is the need for confidentiality. We
              understand this and do everything possible to keep your matters
              private. If we are required to represent you in court we will not
              use your name but will use an alias such as John Doe if that is
              what you want. We have found that almost all cases against priests
              are settled rather than go to trial. A November 2015 article in
              the Connecticut Law Tribune stated that over 95% of these cases
              settle before going to trial.
            </p>

            <h2>2) How much is my case worth?</h2>
            <p className="mb-8">
              The value of every case is different and the maximum value can
              only be determined by an experienced attorney. Many factors go
              into determining the value and these will be discussed in detail
              with you. While every case is different, and past results do not
              ensure future success, we are very proud that we have secured
              millions of dollars in settlements and awards for our sexually
              abused clients. Clients have told us that the closure this
              provides is more valuable than the money.
            </p>

            <h2>3) Do I need an attorney if I’m going to settle?</h2>
            <p className="mb-8">
              You don’t need an attorney to settle. You do need an attorney to
              reach the best settlement for you and your family. Countless hours
              of work and investigation go into every case. Each case at
              Bartlett & Grippe is handled as if it will eventually go to trial.
              This hard work and our reputation will help you receive the
              maximum value for your case. If Bartlett & Grippe accepts your
              case, you will not be shuffled off to an associate.
            </p>
            <h2>4) Why isn’t more being done to stop pedophiles?</h2>
            <p className="mb-8">
              You taking legal action now truly can help not only you and your
              family but the many others that will be molested if the pedophile
              or sexual abuse by priests isn’t stopped. The movie{" "}
              <strong>Spotlight</strong> gives an excellent account of how an
              entire city, country, and world looked the other way concerning
              sexual abuse by priests. It is very common for those in power or
              someone that is known to the child to be the abuser. Stranger
              Danger is not the only thing to teach our young. Over 93% of those
              abused knew the abuser.
            </p>
            <p className="mb-8">
              The movie shows the complexities of dealing with abusers that are
              respected in the community. For more information about the making
              of Spotlight go to{" "}
              <a
                href="https://www3.bostonglobe.com/arts/movies/spotlight-movie/?arc404=true"
                target="_blank"
                rel="noreferrer"
              >
                https://www3.bostonglobe.com/arts/movies/spotlight-movie/?arc404=true
              </a>
            </p>

            <h2>5) Is it morally wrong to sue the Church for pedophilia?</h2>
            <p className="mb-8">
              It is just the opposite. It is not only your right but your
              responsibility to bring legal action against the church and the
              person that did these unspeakable things. Even Pope Francis, the
              leader of the Catholic Church strongly feels there is no other way
              out of this.
            </p>
            <p className="mb-8">
              On May 13, 2018, the television program 60 Minutes aired an
              interview with Pope Francis. The interview was part of a new
              movie’s upcoming release, Pope Francis a Man of His Word. During
              that interview, the Pope was asked about pedophilia in the Church.
              His reaction was intense, moving, and extraordinary.
            </p>
            <p className="mb-8">
              The Pope responded{" "}
              <em>
                “Towards pedophilia, zero tolerance! And the church must punish
                such priests who have that problem, and bishops must remove from
                their priestly functions anyone with that disease, that tendency
                to pedophilia, and that includes to support the legal action by
                the parents before the civil courts. There is no other way out
                of this! Zero tolerance, because it’s a crime, no, worse! It’s
                leaving them alive, but destroyed.”
              </em>
            </p>
            <p className="mb-8">
              Think of the many others that you may keep from being molested.
            </p>

            <h2>6) How long do I have to file a suit?</h2>
            <p className="mb-8">
              It depends on the state you live in. The Connecticut Legislature
              has extended the statute of limitations on filing this type of
              suit to 30 years from when the child turns 18 years old, or age
              48. Bartlett & Grippe represented a client that was awarded over
              three million dollars in a case where the sexual abuse occurred
              over forty years ago. Don’t think you may not have a case. Our
              attorneys can evaluate the facts and circumstances of your
              particular case to determine if you are still within the
              applicable statute of limitations.
            </p>

            <h2>
              7) Where are you located and can you help me if I’m in another
              state?
            </h2>
            <p className="mb-8">
              The Bartlett & Grippe personal injury law firm is located in
              Cheshire, Connecticut. We handle sexual abuse cases across the
              state in every town and have built a network of attorneys across
              the United States that we work with. Don’t settle for less, choose
              Bartlett & Grippe.
            </p>

            <h2>
              Connecticut – Catholic Churches Release Names of “credibly
              accused” pedophiles and some amounts paid to victims
            </h2>
            <h3>Oct 2018 – Bridgeport Diocese</h3>

            <p className="mb-8">
              In October of 2018, the Roman Catholic Diocese of Bridgeport’s
              Bishop Frank J Caggiano said the diocese paid $52.5 million to
              settle 156 allegations of sexual abuse of children by priests
              since 1953.
            </p>
            <p className="mb-8">
              The report shows that settlements ranged from $3,000 to
              $1,425,000. The average settlement was $337,000 and a majority of
              the cases filed involved 12 priests. There were multiple claims
              against each priest. The majority of the cases settled and only
              one case went to trial.
            </p>
            <p className="mb-8">
              In March of 2019, the Bridgeport Diocese updated their list of
              credibly accused priests to include 10 additional priests, 8 of
              whom are deceased.
            </p>
            <p className="mb-8">
              Every case is different and these past results may not be an
              indication of what you will receive. The best way to determine the
              value of your case is to speak with an experienced law firm that
              focuses on sexual abuse by priests.
            </p>

            <h3>Jan 2019 Hartford Archdiocese</h3>
            <p className="mb-8">
              The Hartford Archdiocese Released Names of 48 Accused Priests
              Dating Back to 1953
            </p>
            <p className="mb-8">
              The Hartford Courant has provided{" "}
              <a
                href="https://www.courant.com/news/connecticut/hc-news-accused-priests-hartford-archdiocese-20190122-dm2jfdtkzncwtcpuz46ciry4ia-story.html"
                target="_blank"
                rel="noreferrer"
              >
                additional information and photos of the priests here.
              </a>
            </p>

            <p className="mb-8">
              The New York Times found that of the 48 accused priests listed by
              the Hartford Archdiocese, five were from one church in the small
              shoreline town of Guilford. These disturbing revelations of
              widespread abuse within the Bridgeport and Hartford Archdiocese
              stem from a grand jury investigation by the Pennsylvania attorney
              general which outlined decades of cover-ups within the Catholic
              Church, including discouraging victims from reporting and trying
              to steer local authorities away.
            </p>
            <p className="mb-8">
              As a result, Connecticut state senators are working to change a
              law that currently prevents those over 48 years old from pursuing
              legal action against their sexual abusers, including previously
              protected Roman Catholic priests. If enacted, the proposed
              amendment would eliminate this bar and allow victims of any age to
              pursue claims of childhood sexual abuse.
            </p>
            <p className="mb-8">
              <a
                href="https://www.theday.com/local-news/20190317/legislators-looking-to-help-older-victims-of-priest-abuse-get-settlements"
                target="_blank"
                rel="noreferrer"
              >
                Here is a story in the New London Day
              </a>
            </p>
            <h3>
              Feb 2019 Norwich Diocese Releases Names of 43 Priests Accused of
              Sexual Abuse.
            </h3>
            <p className="mb-8">
              <a
                href="https://www.norwichdiocese.org/Find/Accused-Clergy"
                target="_blank"
                rel="noreferrer"
              >
                Here is a list of the 43 names released by the Norwich Diocese.
              </a>
            </p>
            <p className="mb-8">
              Too little too late. Connecticut’s Catholic leaders have
              apologized, but the individuals who have been affected for life
              deserve justice for their pain and suffering and retribution for
              the financial, emotional and psychological trauma they endured.
              Victims should not be silenced by an arbitrary statute of
              limitations that prevents them from seeking justice.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <h2>Experience and Results</h2>
            <p className="mb-8">
              Bartlett & Grippe has the experience needed to help ensure you get
              the settlement you deserve. The firm’s motto is “Giving Victims a
              Voice”.
            </p>
            <p className="mb-8">
              In 2011 Attorney Bartlett was named lead counsel in one of the
              representative cases in the Roe v. St. Francis Hospital
              consolidated cases, the largest consolidated group of sexual abuse
              cases involving a single perpetrator in Connecticut history. Over
              the past fourteen years, he has successfully represented many
              sexual abuse victims and has obtained multimillion-dollar verdicts
              and settlements for his clients.
            </p>
            <p className="mb-8">
              We are a litigation firm which means we are trial lawyers. We
              prepare for every case as if it will eventually go to trial. Being
              prepared for trial usually results in the defense making you a
              better offer to accept or reject. Whether we go to court is
              ultimately your decision based on the many pros and cons, and
              options we will present to you based on our past experience.
            </p>
            <p className="mb-8">
              If the case goes to trial we will have handled the case from the
              beginning and will know the facts.
            </p>
            <h2>Confidentiality</h2>
            <p className="mb-8">
              These are sensitive matters that don’t have to be made public
              unless you want them to. Our discussions are kept private. We can
              petition the court to use a pseudonym such as John Doe to protect
              your identity when filing a lawsuit to hold the perpetrators of
              your abuse accountable.
            </p>
            <h2>What are the Numbers and are they True?</h2>
            <p className="mb-8">
              There are many statistics concerning the thousands of priests in
              the United States accused of sex abuse. There are reportedly over
              44 priests accused of molestation or sex abuse in Connecticut
              alone. At Bartlett & Grippe our main concern is you, and how we
              can help make your life better. Again, the number we are concerned
              with is one. You.
            </p>
            <p className="mb-8">
              Below are numbers reported by Frontline PBS provided by the
              Catholic Church in 2012.
            </p>
            <p className="mb-8"></p>
            <p className="mb-8"></p>
            <p className="mb-8"></p>
            <p className="mb-8"></p>
            <div className="mb-8 text-center">
              <StaticImage
                className=""
                width="500"
                src="../../images/IMG_1527.png"
                formats={["auto", "webp", "avif"]}
                alt="Chart of sexual abuse allegations against the US Catholic Church"
              />
            </div>

            <h2>Other Resources</h2>
            <p className="mb-8">
              There are several organizations that are working towards an answer
              to this worldwide epidemic in the church.
            </p>
            <h3>
              <a
                href="https://www.snapnetwork.org/"
                target="_blank"
                rel="noreferrer"
              >
                Survivors Network of Those Abused by Priests
              </a>
            </h3>

            <p className="mb-8">
              <strong>CBS News May 2014</strong> –{" "}
              <a
                href="https://www.cbsnews.com/news/vatican-reveals-how-many-priests-defrocked-for-sex-abuse-since-2004/"
                target="_blank"
                rel="noreferrer"
              >
                The Vatican revealed how many priests were defrocked for sex
                abuse since 2004.
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
